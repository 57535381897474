.rs-breadcrumbs {
        background-position: left top;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        clip-path: ellipse(0 0 at 50% -20%);
        -webkit-clip-path: ellipse(0 0 at 50% -20%);
        animation: topclip 1.8s cubic-bezier(.45, .35, .32, .84) forwards;
    .breadcrumbs-inner {
        // padding: 300px 0 300px;  
        position: relative; 
        .page-title {
            font-size: 58px;
            font-weight: 700;
            line-height: 64px;
            margin-bottom: 15px;   
            color: #4c2f91;
            &.new-title{
                margin: 0 auto;
                max-width: 850px;
            }
        }
        ul {
            padding: 0;
            li {
                color: #4c2f91;
                display: inline-block;
                font-size: 16px;
                font-weight: 400;
                a {
                    position: relative;
                    padding-right: 22px;
                    -webkit-transition: 0.3s;
                    transition: 0.3s;
                    color: #4c2f91;
                    &:before{
                        background-color: #fff;
                        content: "";
                        height: 15px;
                        width: 1px;
                        position: absolute;
                        right: 9px;
                        top: 2px;
                        transform: rotate(26deg);
                    }
                }
            }
        } 
        .lined-Font {
            position: absolute;
            top: 80%;
            font-size: 25px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 5px;
            line-height: 30px;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: rgb(94 16 250);
            -webkit-text-fill-color: rgb(142 40 237 / 25%);
            color: #4c2f91;
        }  
    }
   
}
@keyframes topclip { to {-webkit-clip-path: ellipse(108% 100% at 50% 0); clip-path: ellipse(108% 100% at 50% 0)}}

@media #{$lg} {
   
}
@media #{$md} {
    
    
    .lined-Font {
        font-size: 34px ;
        line-height: 40px;
    }
    .rs-breadcrumbs .breadcrumbs-inner .page-title {
        font-size: 32px;
        font-weight: 700;
        line-height: 44px;
    }
    .rs-breadcrumbs.rs-rain-animate img{
        padding-top: 30px;
    }
}

@media #{$sm} {
    .rs-breadcrumbs {
        background:url(../img/banner/banner-9.webp) !important;
        background-position: left top;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        clip-path: ellipse(0 0 at 50% -20%);
        -webkit-clip-path: ellipse(0 0 at 50% -20%);
        animation: topclip 1.8s cubic-bezier(.45, .35, .32, .84) forwards;
     }
    .rs-breadcrumbs .breadcrumbs-inner .page-title {
        font-size: 30px;
        line-height: 40px;
    }
    .lined-Font {
        font-size: 34px !important;
        line-height: 34px !important;
        top: 100% !important;
    }
    .rs-breadcrumbs.rs-rain-animate img{
        padding-top: 30px;
    }
  
    
}
@media #{$mobile} {
    
    .rs-breadcrumbs .breadcrumbs-inner .page-title {
        font-size: 24px;
        line-height: 30px;
    }
    blockquote {
        padding: 30px 30px 1px;
    }
    .lined-Font {
        font-size: 24px !important;
        line-height: 28px;
    }
    .rs-breadcrumbs.rs-rain-animate img{
        padding-top: 30px;
    }
   
}