.rs-process{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .process-wrap {
        background: url(../img/process/work-process.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #03228F;
        margin: 0px 31px 0px 0px;
        padding: 80px 40px 75px 40px;
        transition: $transition;
        border-radius: 10px 10px 10px 10px;
        &.home7 {
            padding: 100px 60px 100px 50px;
        }
    }
    .mobile-img {
        width: 80%;
        margin: 0 auto;
    }
    .rs-addon-number{
        .number-text{
            .number-area{
                display: block;
                font-size: 50px;
                font-weight: 700;
                line-height: 1.4;
                color: #b91d80;
            }
            .number-title{
                .title{
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 600;
                    padding:0 10px 0 0px;
                    color: $titleColor;
                    margin-bottom: 0px;
                }
            }
            .number-txt{
                margin: 0;
                padding:0 10px;
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
    &.modify1{
       
        border-radius: 270px 0px 0px 0px;
        .rs-addon-number{
            .number-text{
                margin: 0px 10px 0px 10px;
                padding: 0px 20px 10px 20px;
             position: relative;
                transition: all 500ms ease;             
                &:hover{
                    transform: translateY(-10px);
                }
                .number-area{
                    font-size: 41px;
                    // font-weight: 600;
                    line-height: 62px;
                    position: relative;
                    color: $whiteColor;
                    display: inline-block;
                    width: 60px;
                    height: 62px;
                    left: -50px;
                    top: -30px;
                    text-align: center;
                    // background: linear-gradient(to right, #9d2482, #1513c1);
                    background-size:contain;
                    background-repeat: no-repeat;
                    background: url(../img/process/l5.webp);
                    transition: all 0.3s ease 0s;
                    // border-radius: 50%;
                    
                }
                .number-title{
                    .title{
                        color: #000;
                        margin-bottom: 15px;
                        margin-top: -20px;
                    }
                }
                .number-txt{
                    color: #000;
                    margin-bottom: 20px;
                    padding-right: 0;
                }
            }
        }
    }
    &.style2{
       
        .addon-process{
              
            .process-img{
                padding: 10px 10px 10px 10px;
                max-width: 75%;
                margin: 0 auto;
                transition: ease 0.6s; 
                img{
                    width: 100%;
                    height: auto;
                }
                &:hover{
                    transform: scale(1.1);
                }
            }
            .process-text{
                text-align: center;
                padding: 15px 0 0;
                .title{
                    font-size: 22px;
                    line-height: 32px;
                    font-weight: 600;
                    color: $titleColor2;
                    margin-bottom: 0;
                    &:hover{
                        color: #061340;
                    }
                }
            }
        }
    }
    &.style3{
        .rs-addon-number{
            margin: 0px -10px 0px 0px;
            padding: 37px 30px 37px 38px;
            background-color: #FFFFFF;
            border-bottom: 7px solid #095fd0;
        }
    } 
    &.style4{
        .process-wrap{
            max-height: 100%;
           
            text-align: center;
        }
        .sec-left {
            transform: rotate(360deg);
            transform-origin: left;
            position: absolute;
            left: 0%;
            top: 25%;
            z-index: 1;
            text-align: center;
             .title {
                font-size: 36px;
                line-height: 46px;
                font-weight: 700;
                color: #ffffff;
                letter-spacing: 2px;
                margin: 5px 20px;
                padding: 25px;
             }
        }
        .rs-addon-number{
            margin: 0px 15px 0px 0px;
            padding: 15px 0;
            background-color: unset;
            height: 100%;
            transition: all 500ms ease;
            border-radius: 5px;
            box-shadow: 0 6px 25px rgb(12 89 219 / 26%);
            &:before, &:after { 
                content: "";
                height: 4px;
                width: 0;
                position: absolute;
                transition: all 0.5s ease-out;
                background: linear-gradient(to right, #9d2482, #1513c1);
            }
            &:before{ 
                left: 0px;
                top: 0;
            }
            &:after {
                right: 0px;
                bottom: 0;
            }
            &:hover{
                transform: translateY(-10px);

                &:before, &:after {
                    width: calc(100% - 5px);
                }
            }
            .number-text{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            
                .number-area{ 
                    display: inline-block;
                    line-height: 50px;
                    z-index: 1;
                    font-size: 30px !important;
                    position: relative;
                    transition: all 0.3s ease 0s;
                    width: 100px;
                    height: 54px;
                    top: -16px;
                    left: -6px;
                    color:#fff;
                    &:after{
                        content: "";
                        width: 100%;
                        height: 100%;
                        width: 60px;
                        height:62px;
                        background-size: contain !important;
                        background-repeat: no-repeat !important;
                        position: absolute;
                        top: -8px;
                        left: -23px;
                        z-index: -1;
                        // transform: rotate(45deg);

                        transition: all 0.3s ease 0s;
                        // border-radius: 50px 50px 50px 50px;
                        background-size:contain;
                        background-repeat: no-repeat;
                        background: url(../img/process/l5.webp);
                        // background: linear-gradient(to right, #9d2482, #1513c1);
                    }
                }
            }
        }
    }
    &.style5{
        .rs-addon-number{
            .number-text{
                .number-area{
                    color: $whiteColor;
                }
                .number-title{
                    .title{
                       color: $whiteColor;
                    }
                }
                .number-txt{
                   color: $whiteColor;
                }
            }
        }
    }
    &.style6{
        .tab-area{
            .nav{
                border-bottom: unset;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                width: 100%;
               
                li{
                    padding: 10px 20px;
                    display: block;
                    position: relative;
                    transition: all ease .6s;
                    cursor: pointer;
                    
                    h3{
                        font-size: 18px;
                        line-height: 32px;
                        font-weight: 600;
                        color: $titleColor3;
                        margin-bottom: 8px;
                       
                    }
                    p {
                        font-size: 16px;
                        line-height: 26px;
                        font-weight: 400;
                        color: $titleColor;
                        height: 0;
                        opacity: 0;
                        margin: 0;
                        transition: all ease .4s;
                    }
                    &.react-tabs__tab--selected {
                        background: linear-gradient(to right, #9d2482, #1513c1);
                        &:before{
                            content: "";
                            background: #5a1ba1;
                            position: absolute;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            width: 25px;
                            height: 25px;
                            bottom: -5px;
                            transform: rotate(45deg);
                            z-index: 5;
                        }
                        h3{
                            color: #fff;
                        }
                        p {
                            height: auto;
                            opacity: 1;
                            transition: all ease .8s;
                        }
                    }
                }
            }
           .tab-content{
                h3{
                    color: #492b90;  
                }
           }
        }
    }
    .shape-animation{
        position: relative;
        .shape-process{
            position: absolute;
            left: 180px;
            top: 50px
        }
    }
}

@media #{$only_lg} {
    .rs-process.style3 .rs-addon-number {
        padding: 30px 20px;
    }
    .rs-process.style6 .tab-area .nav li {
        padding: 15px;
    }
    .rs-process.style6 .tab-area .nav li h3,
    .tab-videos .popup-videos .title {
        font-size: 20px;
        line-height: 30px;
    }
}

@media #{$lg} {
    .rs-process {
        .process-wrap {
            margin: 0 15px 0 0;
            padding: 70px 30px 65px;
        }
    }    
}

@media #{$md} {
    .rs-process {
        .process-wrap {
            margin-right: 0;
        }
        &.style3 {
            .rs-addon-number {
                margin: 0;
            }
        }
        &.style4{
            .rs-addon-number{
                 margin: 0px 15px 0px 18px;
                .number-text{
                    .number-area{ 
                        line-height: 39px;
                        font-size: 20px !important;
                        text-align:left;
                        color:#fff;
                        &:after{
                            content: "";
                            width: 48px;
                            height:50px;
                            top: -8px;
                            left: -23px;
                            background-size:contain;
                            background-repeat: no-repeat;
                            background: url(../img/process/l2.webp);
                        }
                    }
                }
            }
        }
    }  
}

@media #{$mobile} {
    .rs-project .sec-title2 .title {
        font-size: 22px;
    }
    .rs-project.style1 .project-item .project-content .title {
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 4px;
    }

    .rs-process.modify1 .rs-addon-number .number-text {
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 20px;
        padding-right: 20px
    }

    .rs-process .process-wrap.home7 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .rs-process.style6 .tab-area .nav li {
        padding: 15px;
    }
    .rs-process.style6 .tab-area .nav li h3,
    .tab-videos .popup-videos .title {
        font-size: 16px;
        line-height: 20px;
    }
    .style2 .addon-process .process-img {
        padding: 10px 10px 10px 10px;
        max-width: 50% !important;
        margin: 0 auto;
        transition: ease 0.6s;
    }
}
@media #{$xs} {
    .style2 .addon-process .process-img {
        padding: 10px 10px 10px 10px;
        max-width: 40% !important;
        margin: 0 auto;
        transition: ease 0.6s;
    }
}
.services-list {
    display: flex;
    justify-content: start;
    align-content: center;
    width: 100%;

        ul.services-list-left {
            font-size: 18px;
            line-height: 28px;
            width: 40%;
            li{
               padding-bottom: 15px;

               i{
                font-size: 20px;
                color: #07051e;
                letter-spacing: 1px;
               }
            }             
        }
        ul.services-list-right {
            font-size: 18px;
            line-height: 28px;
            width: 60%;
            li{
                padding-bottom: 15px;
 
                i{
                 font-size: 20px;
                 color: #07051e;
                 letter-spacing: 1px;
                }
             }  
        }
        
    }
    .tab-area {
        // background: url(../img/banner/banner-img.jpg);
        // background-size: cover;
        // background-repeat: no-repeat;
        background-position: 20% center;
        padding: 60px 30px;
    }
    .bg-light-c1{
        background: #f1f3ff url(../img/service/icons/bg-box.png) no-repeat;  
        background-position: top right;
        background-size: cover;
        box-shadow: 10px 6px 25px rgb(12 89 219 / 26%);
        border-radius: 10px 10px 50px 10px;
    }
    .bg-light-c2{
        background: #f1f3ff url(../img/service/icons/bg-box2.png) no-repeat;
        background-position: top right;
        background-size: cover;
        box-shadow: 10px 6px 25px rgb(12 89 219 / 26%);
        border-radius: 10px 10px 50px 10px;
    }
    .bg-light-c3{
        background:#f1f3ff url(../img/service/icons/bg-box3.png) no-repeat;
        background-position: top right;    background-size: cover;   box-shadow: 10px 6px 25px rgb(12 89 219 / 26%);
         border-radius: 10px 10px 50px 10px;
    }
    .bg-light-c4{
        background: #f1f3ff url(../img/service/icons/bg-box.png) no-repeat;
        background-position: top right;    background-size: cover;  box-shadow: 10px 6px 25px rgb(12 89 219 / 26%); 
         border-radius: 10px 10px 50px 10px;
    }