.rs-faq {
	.accodion-style1 {
		.accordion__item {
			border: none;
			margin-bottom: 15px;
			border-radius: 30px;
			background: $whiteColor;
			overflow: hidden;
			.accordion__heading {
				.accordion__button {
					position: relative;
					background: transparent;
					padding: 10px 10px;
					font-size: 18px;
					line-height: 23px;
					font-weight: 600;
					color: #101010;
					&::before {
						display: none;
					}
					&::after {
						position: absolute;
						display: none;
						content: "+";
						top: 39%;
						transform: translateY(-50%);
						right: 20px;
						margin: 0;
						font-size: 20px;
						width: 35px;
						height: 35px;
						border-radius: 50%;
						background: linear-gradient(180deg, #85218d 0%, #2816b9 100%);
						color: $whiteColor;
						text-align: center;
						line-height: 30px;
						font-weight: 700;
						transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
						z-index: 1;
					}
				}
				.accordion__button[aria-expanded=true]::after,
				.accordion__button[aria-selected=true]::after {
					content: "-";
				}
			}
			.accordion__panel {
				padding: 0 30px 30px 30px;
				p {

					color: #101010;
					line-height: 26px;
					margin: 0;
				}
			}
			&:last-child {
				margin: 0;
			}
			
		}
	}
}

.job-detail.ree-card {
		background: #a3cbfe40;
		place-items: start;
		border-radius: 14px;
		padding: 30px 30px;
		box-shadow: 3px 10px 10px -2px rgb(62 132 223 / 28%);
		transition: all .6s ease;
		margin-bottom: 15px;
		display: flex;
		flex-direction: row;
		&:hover{
			box-shadow: 3px 10px 10px -2px rgb(62 132 223 / 28%);
			background: #fff;
		}
		.job-title{
			h4 {
				color: #4c2f91;
				margin-bottom: 15px;
			}
		}
		.apply-btn{
			margin-left: auto;
			display: flex;
			align-items: center;
		}
		
}
@media #{$md} { 
	.job-detail.ree-card{
		flex-direction: column !important;

		.apply-btn{
			margin-left: 0;
			display: flex;
			align-items: left;
		}
	}
 }

@media #{$sm} {
	.job-apply-form {
		top: 35px !important;
		height: 180px !important;
		ul{
			 
		flex-direction: column !important;

		li{
			margin-bottom: 20px;
		}
	}
}
}
@media #{$xs} {
	.job-apply-form {
			top: 35px !important;
			height: 180px !important;
			max-width: 400px !important;
			ul{
				li{
					a{
					font-size: 16px !important;
					}
				}
			}
	}
}
.job-apply-form {
    background-color: #fff;
    padding: 20px;
    display: flex;
    box-shadow: 0px 3px 66px #0000000d;
    border-radius: 10px;
    height: 142px;
    max-width: 900px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 60px;
	 ul {
		display: flex;
		gap: 20px;
		justify-content: center;
		align-items: center;
		li {
			a {
				color: #4c2f91;
				border: 2px solid #4c2f91;
				border-radius: 10px;
				padding: 12px 30px;
				font-size: 18px;
				font-weight: normal;
			}
		}
	 }	
}

.job-desc {
    list-style-type: none;
	li {
		padding-left: 20px;
		margin-bottom: 10px;
		position: relative;
		text-align: left;
		&:before {
			content: "";
			min-width: 10px;
			min-height: 10px;
			border: 1px solid #777;
			position: absolute;
			left: -6px;
			top: 6px;
			border-radius: 50%;
		}
	}
}