
.cta-bg1{
    background: url(../../assets/img/newsletter/cta-bg-3.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


.rs-cta{
    &.style1{
        .title-wrap{
            .epx-title{
                font-size: 62px;
                font-weight: 700;
                line-height: 64px;
                color: #fff;
                cursor: pointer;
                letter-spacing: 1.7px;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #fff;
                -webkit-text-fill-color: transparent;
                line-height: 1;
                background-clip: text;
                -webkit-background-clip: text;
                background-size: 100%;
                transition: all 0.6s ease;
                margin-bottom: 10px;
                &:hover {
                    background-color: #fff;
                }
            }
            .exp-text{
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                color: #fff;
                padding: 15px 0 0;
                letter-spacing: 0.8px;
                
            }
        }
        span{
            font-weight: 500;
            margin: 0 0 10px;
            display: block;
            line-height: 28px;
            font-size: 14px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #FFFFFF;
        }
        &.bg-widget{
            background-image: linear-gradient(150deg, #03228f 46%, #0e73e4 100%)
        }
    }
}
@media #{$lg} {
    .rs-cta.style1 .title-wrap .epx-title{
        font-size: 32px;
        line-height: 38px;
    }
}
@media #{$mobile} {
    .rs-cta.style1 .title-wrap .epx-title{
        font-size: 32px;
        line-height: 38px;
    }
}