
.rs-call-us{
    background: url(../img/bg/bg-4.webp);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #000060;
    position: relative;
    // .image-part{
    //     img{
    //         position: absolute;
    //         left: 0;
    //         bottom: 0;
    //         max-height: 585px;
    //     }
    // }
    // .address-item{
    //     .address-icon{
    //         display: inline-block;
    //         position: relative;
    //         height: 70px;
    //         width: 70px;
    //         line-height: 70px;
    //         border-radius: 100%;
    //         text-align: center;
    //         background: $whiteColor;
    //         i{
    //             text-align: center;
    //             color: $primaryColor;
    //             border-radius: 100%;
    //             font-size: 25px;
    //             position: relative;
    //             line-height: 70px;
    //             z-index: 9;
    //             width: 100%;
    //             vertical-align: middle;
    //             margin-left: 0;
    //             height: auto;
    //         }
    //         &:before{
    //             content: "";
    //             position: absolute;
    //             z-index: 0;
    //             left: 50%;
    //             top: 50%;
    //             transform: translateX(-50%) translateY(-50%);
    //             display: block;
    //             width: 70px;
    //             height: 70px;
    //             border-radius: 50%;
    //             animation: pulse-border 1500ms ease-out infinite;
    //             background: $whiteColor;
    //         }
    //     }
    // }
    .sec-title3{
        .sub-text{
            font-size: 16px;
            line-height: 26px;
            font-weight: 500;
            color: $whiteColor;
            display: block;
            margin-bottom: 20px;
        }
        .title{
            font-size: 62px;
            font-weight: 700;
            line-height: 64px;
            color: #fff;
            cursor: pointer;
            letter-spacing: 1.7px;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #fff;
            -webkit-text-fill-color: transparent;
            line-height: 1;
            background-clip: text;
            -webkit-background-clip: text;
            background-size: 100%;
            transition: all 0.6s ease;
            margin-bottom: 10px;
            &:hover {
                background-color: #fff;
                background-size: 90%;
            }
        }
        .desc{
            font-size: 19px;
            font-weight: 500;
            color: #fff;
            margin: 0;
        }
    }
}
