
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-image:linear-gradient(to right, rgb(157, 36, 130), rgb(21, 19, 193));;
}
.nav-link{
  font-family: 'Poppins', sans-serif !important;
  font-size:17px;
  border-radius: 30px !important;
}

.rs-technology{
    .technology-item{
        text-align: center;
        padding: 15px;
        border: 1px solid #5ba8fa54;
        height: 80px;
        margin-bottom: 20px;
        a{
            overflow: hidden;
        }
    }
    &.style2{
        
       .technology-wrap{
            .rs-grid-figure{
                position: relative;
                text-align: center;
                padding: 10px;
                border-style: solid;
                border-width: 1px 1px 1px 1px;
                border-color: #a7acb4;
                border-radius: 4px 4px 4px 4px;
                transition: all ease 0.4s;
                max-width: 60%;
                margin: 0 auto;
                .logo-img{
                    overflow: hidden;
                    a{
                        overflow: hidden;
                        display: inline-block;
                        position: relative;
                        .hover-img{
                            transition: all ease .4s;
                            filter: grayscale(100%);
                            display: block;
                            position: absolute;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            visibility: hidden;
                        }
                        .main-img{
                            transition: all ease .4s;
                            filter: grayscale(100%);
                        }
                    }
                }
            }
            .logo-title{
                position: relative;
                text-align: center;
                padding: 30px 0px 0px 0px;
                .title{
                    font-size: 18px;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #000;
                    margin: 15px 0px 0px 0px;
                }
                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    height: 20px;
                    width: 1px;
                    transform: translateX(-50%);
                    background-color: #000;
                    -webkit-transition: all 0.25s ease;
                    transition: all 0.25s ease;
                }
                &:after{
                    content: '';
                    position: absolute;
                    top: 20px;
                    left: 50.3%;
                    height: 12px;
                    border-radius: 50%;
                    width: 12px;
                    transform: translateX(-50%);
                    background-color: #000;
                    -webkit-transition: all 0.25s ease;
                    transition: all 0.25s ease;
                }
            }
            &:hover{
                .rs-grid-figure{
                    border-color: #fff;
                    background-color: #dee6ff;
                    border: 1px solid #ddd;
                    .logo-img{
                        a{
                            .hover-img{
                                display: block;
                                opacity: 3;
                                visibility: visible;
                                transform: translateY(0%);
                                filter: grayscale(0%);
                            }
                            .main-img{
                                display: block;
                                visibility: hidden;
                                transform: translateY(100%);
                            }
                        }
                    }
                }
                .logo-title{
                 
                    &:before{
                        background-color: #000;
                    }
                    &:after{
                        background-color: #000;
                    }
                }
            }
       }
    }
}

@media #{$xs} {
    .rs-technology .technology-item img {
        max-width: 150px;
    }
    .rs-technology.style2 .technology-wrap {
        max-width: 240px;
        margin: 0 auto;
    }
}